<app-template-map
  [disabled]="disabled"
  [value]="value"
  [control]="control"
  [minlength]="minlength"
  [maxlength]="maxlength"
  [options]="options"
  [location]="location"
  [polygon]="polygon"
  [label]="label"
/>
