import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren, inject } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CaptionComponent } from "src/app/components/default/caption/caption.component";
import { CaptionContentPart } from "src/app/components/default/caption/caption.interface";
import { ChartComponent } from "src/app/components/default/chart/chart.component";
import { ChartContentPart } from "src/app/components/default/chart/chart.interface";
import { FormComponent } from "src/app/components/default/form/form.component";
import { Mode } from "src/app/components/default/form/form.definition";
import { FormContentPart } from "src/app/components/default/form/form.interface";
import { MapComponent } from "src/app/components/default/map/map.component";
import { MapContentPart } from "src/app/components/default/map/map.interface";
import { MenuService } from "src/app/components/default/menu/menu.service";
import { TableComponent } from "src/app/components/default/table/table.component";
import { TableContentPart } from "src/app/components/default/table/table.interface";
import { TileComponent } from "src/app/components/default/tile/tile.component";
import { TileContentPart } from "src/app/components/default/tile/tile.interface";
import { DialogService, InstanceData } from "src/app/components/global/dialog/dialog.service";
import { ContentDialogData } from "src/app/components/global/dialog/impl/content-dialog/content-dialog.component";
import { ErrorMessageComponent } from "src/app/components/global/snackbar/impl/error-message/error-message.component";
import { SnackbarService } from "src/app/components/global/snackbar/snackbar.service";
import { DefaultComponent } from "src/app/default.component";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { Layout } from "src/enums/layout";
import { ENVIRONMENT } from "src/environments/environment";
import { Action } from "src/interfaces/post-request/post-request";
import { ApplicationService } from "src/services/application.service";
import { HttpService } from "src/services/http.service";
import { SessionService } from "src/services/session.service";
import { ContentCaption } from "./impl/content-caption";
import { ContentChart } from "./impl/content-chart";
import { ContentForm } from "./impl/content-form";
import { ContentMap } from "./impl/content-map";
import { ContentTable } from "./impl/content-table";
import { ContentTile } from "./impl/content-tile";

export interface FFWDContent {
  content: {
    title: string;
    devinfo: string;
    coachcontext?: string;
    contentparts: ContentPartResponse[];
    id: string;
    context?: { id: string; value: string }[];
    gridlayout: string;
  };
}

export type ContentResponse = { content: string; refreshSession: string }[];
export type ContentPartType = "INVALID" | "Table" | "Form" | "Chart" | "Timeline" | "Caption" | "Tile" | "Map";
export type ContentPartResponse = FormContentPart | TableContentPart | ChartContentPart | CaptionContentPart | TileContentPart | MapContentPart;
export type ContentPart = ContentForm | ContentTable | ContentChart | ContentCaption | ContentTile;
export type ComponentSize = "full" | "auto";

@Component({
  standalone: true,
  selector: "app-content",
  imports: [CommonModule, FormComponent, TableComponent, ChartComponent, CaptionComponent, TileComponent, MapComponent],
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.less", "./content.print.less"],
})
export class ContentScreenComponent extends DefaultComponent implements OnInit, OnDestroy, AfterViewInit {
  public application: ApplicationService;
  public route: ActivatedRoute;
  private router: Router;
  private http: HttpService;
  public session: SessionService;
  private translate: TranslateService;
  private menu: MenuService;
  private snackbar: SnackbarService;
  private dialog: DialogService;

  @Input()
  public id: string | null;

  @Input()
  public dynamicjson: string | null;

  @Input()
  public content: FFWDContent | null;

  @Input()
  public dialogRef: string | null;

  @ViewChildren(TableComponent)
  public tables: QueryList<TableComponent> | null;

  @ViewChildren("component")
  public componentRefs: QueryList<ElementRef<HTMLElement>>;

  @Output()
  public loading: EventEmitter<boolean>;

  public components: Map<number, ContentPart>;

  public fullsize: boolean;
  public isGrid: boolean;

  public constructor() {
    super();
    this.application = inject(ApplicationService);
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.http = inject(HttpService);
    this.session = inject(SessionService);
    this.translate = inject(TranslateService);
    this.menu = inject(MenuService);
    this.snackbar = inject(SnackbarService);
    this.dialog = inject(DialogService);
    this.dialogRef = null;

    this.tables = null;
    this.componentRefs = new QueryList();
    this.loading = new EventEmitter();

    this.id = null;
    this.dynamicjson = null;
    this.content = null;

    this.fullsize = false;
    this.isGrid = false;

    this.components = new Map<number, ContentPart>();
  }

  public ngOnInit(): void {
    if (this.id) {
      this.load(this.id, true, this.dynamicjson ?? undefined);
    } else if (!this.content) {
      this.addSubscription(
        this.route.params.subscribe((params: Params) => {
          const id = params["id"];
          const dynamicjson = this.router.lastSuccessfulNavigation?.extras.state?.["dynamicjson"];
          this.load(id, undefined, dynamicjson ? JSON.stringify(dynamicjson) : undefined);
        }),
      );
    } else {
      this.load("");
    }
  }

  public ngAfterViewInit(): void {
    this.addSubscription(
      this.componentRefs.changes.subscribe((list: QueryList<ElementRef<HTMLElement>>) => {
        if (this.components.size == list.length) {
          const first = list.get(0);
          if (first) {
            first.nativeElement.focus();
          }
        }
      }),
    );
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    for (const component of this.components.values()) component.deconstruct();
  }

  public async load(id: string, modal?: boolean, dynamicjson?: string): Promise<void> {
    let response: ContentResponse | null = null;
    let data: FFWDContent | null = null;

    try {
      if (!modal) this.application.loading.next(true);
      this.loading.emit(true);
      this.id = id;
      this.components.clear();

      if (this.content) {
        data = this.content;
      } else {
        const params: Record<string, unknown> = {
          ID: id,
          modal: modal ?? null,
          dynamicjson: dynamicjson ?? null,
        };
        response = await this.http.retrieve<ContentResponse>(ROUTES_CONFIG.contentUrl, params);
        if (response[0].refreshSession.length) await this.session.update(JSON.parse(response[0].refreshSession));
        data = JSON.parse(response[0].content);
      }

      if (data) {
        const contentid = data.content.id;
        const title = data.content.title.replace("_", " ");

        if (!modal) {
          this.application.title.next(title);
        }
        this.isGrid = this.application.layout == Layout.DESKTOP && data.content.gridlayout == "true";
        this.application.debugContext.next(data.content.devinfo);
        this.application.context.next(data.content.context ?? []);
        this.menu.checklist.next(data.content.coachcontext || null);

        this.addSubscription(
          this.application.layoutChanged.subscribe(
            () => (this.isGrid = this.application.layout == Layout.DESKTOP && data != null && data.content.gridlayout == "true"),
          ),
        );

        if (!this.content && !this.application.index) throw new Error("Invalid tabindex in ContentComponent");

        for (const [index, part] of data.content.contentparts.entries()) {
          let component: ContentPart | null = null;
          switch (part.type) {
            case "table": {
              component = this.createTable(contentid, <TableContentPart>part);
              break;
            }
            case "form": {
              component = this.createForm(contentid, <FormContentPart>part);
              break;
            }
            case "graph":
              component = this.createChart(contentid, <ChartContentPart>part);
              break;

            case "caption":
              component = this.createCaption(contentid, <CaptionContentPart>part);
              break;

            case "tile":
              component = this.createTile(contentid, <TileContentPart>part);
              break;

            case "map":
              component = this.createMap(contentid, <MapContentPart>part);
              break;
          }

          if (component) {
            component.title = component.title || data.content.title;
            component.gridStyle = this.isGrid ? component.parseGridStyle((<FormContentPart>part).gridlayout) : null;
            this.components.set(index, component);
          } else {
            throw new Error(`Could not find component: ${part.type}`);
          }
        }

        if (ENVIRONMENT.DEBUG)
          console.warn("[CONTENT] Loading => ", {
            id: id,
            components: this.components.values(),
            info: data.content.devinfo,
            data,
          });

        this.fullsize = !!Array.from(this.components.values()).filter((component, index) => {
          if (index != 0 && index != this.components.size - 1) component.size = "auto";
          return component.size == "full";
        }).length;

        this.application.loading.next(false);
        this.loading.emit(false);
      } else {
        throw new Error("Invalid Data:FFWDContent");
      }
    } catch (err) {
      console.error(`Unable to load content[${id}]`, err, response);
      this.application.loading.next(false);
      this.loading.emit(false);
      this.router.navigate(["app/home"]);
      this.snackbar.open<string>(ErrorMessageComponent, "DIALOG.ERRORS.INVALIDCONTENT");
    }
  }

  public sendAction(id: string, data: string): Promise<unknown> {
    return this.http.send<Action>(ROUTES_CONFIG.actionurl, {
      actionData: data,
      FFWDActionID: id,
    });
  }

  public createTable(contentid: string, data: TableContentPart): ContentTable {
    const component = new ContentTable(data.id, this.http, this.application, undefined, this.session);
    component.tabindex = this.application.index;
    component.contentid = contentid;
    component.contentpartid = data.id.split("ID")[1];
    component.activeFilters = data.groupfilters;
    component.headers = component.parseHeaders(data.headerfields);
    component.fields = component.parseFields(data.data);
    component.statusactionid = data.setstatusaction;
    component.searchactionid = data.setsearchfiltersaction;
    component.sortactionid = data.setcolumnsortaction;
    component.searchkeys = data.searchfilter?.map((key) => key.filter) || [];
    component.buttons = data.buttons;
    component.options = {
      delete: data.candelete == "True",
      download: data.candownload == "True",
      resultslimited: data.resultslimited,
      resultslimitedmaxreached: data.resultslimitedmaxreached,
      foot: data.foot == "1",
      head: data.head == "1",
    };
    component.size = data.wp === "true" ? "full" : "auto";
    return component;
  }

  public createForm(contentid: string, data: FormContentPart): ContentForm {
    const component = new ContentForm(data.id, this.http, this.application, this.session, this.translate, this.dialog);
    // If none of these buttons id's are present, make mode view
    // const editModeButtons = ["1", "2", "3", "11"];
    // component.mode = data.buttons.some(({id}) => editModeButtons.includes(id)) ? Mode.EDIT : Mode.VIEW;
    component.mode = data.buttons.length ? Mode.EDIT : Mode.VIEW;

    component.title = data.title;
    component.attachments = component.parseAttachments(data.attachments);
    component.fields = component.parseFields(data.formfields);
    component.tabindex = this.application.index;
    component.contentid = contentid;
    component.contentpartid = data.id.split("ID")[1];
    component.options.attachments = data.hasfiles === "true";
    component.buttons = data.buttons;
    component.size = data.wp === "true" ? "full" : "auto";
    component.buttonenabled = data.buttonenabled === "true";

    const dialogRef = this.dialogRef ? (this.dialog.instances.get(this.dialogRef) as InstanceData<ContentDialogData> | undefined) : undefined;
    if (dialogRef?.data.title) {
      component.title = dialogRef.data.title.label;
      component.options.head = true;
      component.options.title = true;
    }

    this.application.limitedPrint.next(data.limitedprintenabled === "true");
    return component;
  }

  public createChart(contentid: string, data: ChartContentPart): ContentChart {
    const component = new ContentChart(data.id);

    component.charts = [
      {
        rows: data.rows,
        title: data.legendatitle,
      },
    ].map((input) => component.getChart(input.title, input.rows));
    component.size = data.wp === "true" ? "full" : "auto";
    return component;
  }

  public createCaption(contentid: string, data: CaptionContentPart): ContentCaption {
    const component = new ContentCaption(data.id);
    component.size = data.wp === "true" ? "full" : "auto";
    return component;
  }

  public createTile(contentid: string, data: TileContentPart): ContentTile {
    const component = new ContentTile(data.id, this.http, this.application);
    component.headers = component.parseHeaders(data.headerfields);
    component.tiles = component.parseTiles(data);
    component.size = data.wp === "true" ? "full" : "auto";
    return component;
  }

  public createMap(contentid: string, data: MapContentPart): ContentMap {
    const component = new ContentMap(data.id, this.http, this.application);
    component.markers = component.parseMarkers(data);
    component.size = data.wp === "true" ? "full" : "auto";
    return component;
  }

  /**
   * Onscroll event
   * @param event
   */
  public scroll(event: Event): void {
    const target = <HTMLElement>event.target;

    for (const table of this.tables ?? []) {
      table.onScroll(target.scrollHeight - target.offsetHeight, target.scrollTop);
    }
  }
}
