import { Injectable, inject } from "@angular/core";
import { Subject, firstValueFrom } from "rxjs";
import { SessionApproveLocationResponse, SessionApproveLocationResult } from "src/interfaces/session/session-approve-location";
import { v4 as uuidv4 } from "uuid";
import { Session } from "../classes/Session";
import { ROUTES_CONFIG } from "../config/routes.config";
import { STORAGE } from "../config/storage.config";
import { LOCALES } from "../enums/locales";
import { SessionPermission } from "../enums/session-permission";
import { SessionLoginJWT } from "../interfaces/session/session-login-jwt";
import { SessionLoginPermalink } from "../interfaces/session/session-login-permalink";
import { SessionLoginQR } from "../interfaces/session/session-login-qr";
import { SessionLoginResponse } from "../interfaces/session/session-login-response";
import { SessionLoginTraditional } from "../interfaces/session/session-login-traditional";
import { SessionLoginRequest } from "../interfaces/session/session-loginrequest";
import { SessionLogoutResponse } from "../interfaces/session/session-logout-response";
import { SessionRecover } from "../interfaces/session/session-recover";
import { SessionRecoverResponse } from "../interfaces/session/session-recover-response";
import { SessionResponse } from "../interfaces/session/session-response";
import { SessionRole } from "../interfaces/session/session-role";
import { SessionWorkspace } from "../interfaces/session/session-workspace";
import { ApplicationService } from "./application.service";
import { HttpService } from "./http.service";
import { SOCKET_IN, WebSocketService } from "./websocket.service";

export interface SessionStatus {
  id: string;
  label: string;
  actionid: string;
}

export enum SESSION_IN {
  TIMEOUT = "LoginExpiryNotification",
  STO = "STO",
}

export interface SESSION_EVENT {
  event: SESSION_EVENT;
  params: Record<string, unknown>;
}

@Injectable({
  providedIn: "root",
})
export class SessionService extends Session {
  private application: ApplicationService;
  private http: HttpService;
  private socket: WebSocketService;

  public event: Subject<SESSION_EVENT>;

  public workspaces: SessionWorkspace[];
  public roles: SessionRole[];
  public statuses: SessionStatus[];

  public verified: boolean;

  public constructor() {
    super(uuidv4());

    this.application = inject(ApplicationService);
    this.http = inject(HttpService);
    this.socket = inject(WebSocketService);

    this.event = new Subject();

    this.workspaces = [];
    this.roles = [];
    this.statuses = [];

    this.verified = true;

    this.socket.listen(SOCKET_IN.SESSION, (...params: unknown[]) => {
      const EVENT = <string>params.shift();

      switch (EVENT) {
        default:
          console.error("Unknown incoming session event => ", { EVENT });
          break;
      }
    });
  }

  /**
   * Force update session
   * @param tabIndex
   * @returns
   */
  public async refresh(): Promise<void> {
    if (this.application.index != null) {
      const response = await this.http.send<SessionResponse>(ROUTES_CONFIG.checkSessionUrl, {});
      this.authenticated.next(true);
      await this.update(response);
    } else {
      throw new Error("Missing tabindex");
    }
  }

  /**
   * Tries to establish a secure session
   */
  public async initialize(): Promise<void> {
    try {
      await this.refresh();
    } catch {
      this.authenticated.next(false);
      this.application.setTabIndex(null);
    }
  }

  /**
   * Open a secured session
   * @param data
   * @returns
   */
  public async connect(
    data: SessionLoginTraditional | SessionLoginQR | SessionLoginPermalink | SessionLoginJWT | SessionLoginRequest,
    url = ROUTES_CONFIG.loginUrl,
  ): Promise<void> {
    const response = await this.http.send<SessionLoginResponse>(url, data);
    console.error("CONNECT USER!!!! => ", response);
    if ("session" in response) {
      await this.update(response);
      this.authenticated.next(true);
    } else {
      this.application.onMessage(response.messages.Messages);
      this.authenticated.next(false);
      this.application.setTabIndex(null);
    }
  }

  /**
   * Recover account
   * @param data
   */
  public async recover(data: SessionRecover): Promise<void> {
    const res = await this.http.send<SessionRecoverResponse>(ROUTES_CONFIG.forgotpasswordurl, data);

    if (res.response && res.response.result == "OK") {
      this.application.onMessage(res.response.messages);
    } else {
      throw new Error("Invalid recovery");
    }
  }

  /**
   * Set/Update data of current session
   * @param index
   * @param data
   */
  public async update(data: SessionResponse): Promise<void> {
    if (await this.handleSession(data)) {
      const workspaces = data.workspaces;
      const workspace = workspaces.find((workspace) => workspace.id == data.workspace.id);

      this.id = data.session.sessionid;
      this.userId = parseInt(data.user.OriginalUserID);
      this.name = data.user.displayname;
      this.permissions = this.getPermissions(data);

      this.statuses = data.conference.statuses.map((status) => ({
        id: status.idpID,
        label: status.omsStatus,
        actionid: status.setstatusaction,
      }));

      this.status.next(data.conference.idgSchaalIDConferenceStatus);

      workspace ? localStorage.setItem(STORAGE.WORKSPACE_ID, workspace.id) : localStorage.removeItem(STORAGE.WORKSPACE_ID);

      if (workspace) {
        const role = workspace.roles.find((role) => role.id == data.role.id);
        this.workspaces = workspaces;
        this.roles = workspace.roles;
        this.role.next(role || null);
        this.workspace.next(workspace);

        role ? localStorage.setItem(STORAGE.ROLE_ID, role.id) : localStorage.removeItem(STORAGE.ROLE_ID);

        console.warn(`[SESSION] Setting session => `, {
          id: this.id,
          name: this.name,
          permissions: this.permissions,
          workspace: this.workspace.value,
          workspaces: this.workspaces,
          role: this.role.value,
          roles: this.roles,
          sessiondata: data.session,
          translationid: data.gettranslationaction,
        });
      } else {
        throw new Error(`Could not find workspace => ${data.workspace.id}`);
      }

      this.translationid = data.gettranslationaction;
      this.application.setTabIndex(data.session.tabindex);
      this.application.locale.next(LOCALES[data.session.lcode] || LOCALES.EN);
    } else {
      console.warn("[SESSION] Did not update session => session is invalid.");
    }
  }

  /**
   * Close the current session
   */
  public disconnect(): void {
    this.http.send<SessionLogoutResponse>(ROUTES_CONFIG.logoffUrl, { tabindex: this.application.index });
    this.id = uuidv4();
    this.name = "Guest";
    this.userId = -1;
    this.permissions = [];
    this.workspaces = [];
    this.roles = [];
    this.role.next(null);
    this.workspace.next(null);
    this.authenticated.next(false);
    this.application.setTabIndex(null);
    this.timedout.next(false);
  }

  /**
   * Change sessions role
   * @param id
   * @returns
   */
  public async changeRole(role: SessionRole): Promise<SessionResponse | void> {
    const current = await firstValueFrom(this.role);
    try {
      const response = await this.http.send<{ session: SessionResponse }>(ROUTES_CONFIG.changeRoleUrl, {
        currentRoleID: current?.id,
        newRoleID: role.id,
        tabindex: this.application.index,
      });
      await this.update(response.session);
      return response.session;
    } catch (err) {
      throw new Error(`Unable to change to role => ${role.id}`);
    }
  }

  /**
   * Change session workspace
   * @param id
   * @returns
   */
  public async changeWorkspace(workspace: SessionWorkspace): Promise<SessionResponse | void> {
    const current = await firstValueFrom(this.workspace);
    try {
      const response = await this.http.send<{ session: SessionResponse }>(ROUTES_CONFIG.changeWorkspaceUrl, {
        currentWorkspaceID: current?.id,
        newWorkspaceID: workspace.id,
        tabindex: this.application.index,
      });
      await this.update(response.session);
      return response.session;
    } catch (err) {
      throw new Error(`Unable to change to workspace => ${workspace.id}`);
    }
  }

  /**
   * Change session status
   * @param status
   */
  public async changeStatus(status: SessionStatus): Promise<void> {
    try {
      this.status.next(status.id);
    } catch (err) {
      throw new Error(`Unable to change status => ${status.label}`);
    }
  }

  public async approveLocation(locationid: string): Promise<boolean> {
    try {
      const response = await this.http.send<SessionApproveLocationResponse>(ROUTES_CONFIG.approvelocationurl, { locationid });
      const { result } = JSON.parse(response.response) as SessionApproveLocationResult;
      if (result === "ok") {
        return true;
      } else {
        throw new Error("Approve Location Failed: " + JSON.stringify(response));
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Get User permissions
   * @param data
   */
  private getPermissions(data: SessionResponse): SessionPermission[] {
    const permissions: SessionPermission[] = [];
    permissions.push(SessionPermission.SHOW_CONFERENCE);
    if (data.role.id === "33") permissions.push(SessionPermission.SHOW_SCHEDULES);
    if (data.role.hascockpit) permissions.push(SessionPermission.SHOW_COCKPIT);
    if (data.role.haschart) permissions.push(SessionPermission.SHOW_CHART);
    if (data.role.hascoach) permissions.push(SessionPermission.SHOW_COACH);
    if (data.role.hassearch) permissions.push(SessionPermission.SEARCH_PAGE);
    if (!data.role.donotsavetablesearch) permissions.push(SessionPermission.SAVE_TABLE_SEARCH);
    if (!data.role.donotsavetablefilters) permissions.push(SessionPermission.SAVE_TABLE_FILTERS);
    return permissions;
  }

  /**
   * Check and set the session statuses (ex. timeout)
   * @param keys
   * @returns boolean
   */
  private async handleSession(data: SessionResponse): Promise<boolean> {
    const keys = Object.keys(data).map((key) => key.toUpperCase());
    for (const key of keys) {
      switch (key) {
        case "STO":
        // if (keys[key].length > 0) {
        //   this.timedout.next(true);
        //   return false;
        // }
        // break;
        case "SCP":
        case "SCO":
        case "SCL":
          if (keys[key].length > 0) {
            this.socket.disconnect();
            this.disconnect();
            return false;
          }
          break;

        default:
          return true;
      }
    }
    return true;
  }
}
