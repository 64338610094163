<div class="container-fluid">
  <div class="row tableContainer">
    <div class="col-md">
      @if (settings.head || settings.resultslimited) {
        <div class="row table-header-container">
          @if (settings.head) {
            <div class="col-md-12 table-head">
              <div class="search-terms">
                <form>
                  @for (key of searchkeys; track key) {
                    <mat-chip-row (removed)="removeSearchkey(key)">
                      {{ key }}
                      <button matChipRemove [attr.aria-label]="'remove ' + key">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                  }
                </form>
              </div>

              <div class="table-addons">
                <ul>
                  @if (settings.search) {
                    <li class="searchContainer">
                      <form (keydown)="searchShortcuts($event.code)">
                        <app-prefix-oms (controlchanged)="setSearchControl($event)" [counter]="false" [label]="'TABLE.SEARCHBAR' | translate" />
                      </form>
                      <button type="button" (click)="setSearchkeys()" [tooltip]="'TOOLTIP.TABLE.SEARCH'">
                        <mat-icon>search</mat-icon>
                      </button>
                      <button type="button" (click)="addSearchkeys()" [tooltip]="'TOOLTIP.TABLE.ADD_SEARCH'">
                        <mat-icon>zoom_in</mat-icon>
                      </button>
                    </li>
                  }
                  @if (settings.filter && fields.size) {
                    @if (filteractive) {
                      <li class="action" [ngClass]="{ filterHighlight }">
                        <button type="button" (click)="toggleFilter()" [tooltip]="'TOOLTIP.TABLE.HIDE_FILTERS'">
                          <mat-icon>filter_alt_off</mat-icon>
                        </button>
                      </li>
                    } @else {
                      <li class="action" [ngClass]="{ filterHighlight }">
                        <button type="button" (click)="toggleFilter()" [tooltip]="'TOOLTIP.TABLE.SHOW_FILTERS'">
                          <mat-icon>filter_alt</mat-icon>
                        </button>
                      </li>
                    }
                  }
                  @if (settings.filter || settings.search) {
                    <li class="action">
                      <button type="button" [tooltip]="'TOOLTIP.TABLE.CLEAR'" (click)="onClear()">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </li>
                  }
                </ul>
              </div>
            </div>
          }
          @if (settings.resultslimited && settings.resultslimitedmaxreached) {
            <div class="col-md-12 resultslimited">{{ "TABLE.LIMITEDRESULTS_MESSAGE" | translate }}</div>
          }
        </div>
      }

      <div class="row table-body">
        <div class="col-md p-0 table-container" #tableContainer>
          @if (!hasRows) {
            <span class="no-rows">{{ "TABLE.NO_ROWS" | translate }}</span>
          }

          <mat-accordion class="table clean plain" multi>
            @for (group of fields | keyvalue; track group) {
              <ng-container *ngTemplateOutlet="loop; context: { data: { rows: group.value.shown, groups: group.value.groups, group: group.value, first: true } }" />
            }
          </mat-accordion>
        </div>

        <div class="switch-filters" [style.display]="settings.filter && filteractive && fields.size ? 'block' : 'none'">
          <div class="toggle">
            <span (click)="toggleAllGroups()">{{ (someFiltersActive() ? "TABLE.FILTERS.TURN_ALL_OFF" : "TABLE.FILTERS.TURN_ALL_ON") | translate }}</span>
          </div>

          <mat-accordion class="table clean plain">
            @for (field of fields | keyvalue; track field) {
              <ng-container *ngTemplateOutlet="switchfiltergrouped; context: { data: {} }" />
            }
          </mat-accordion>
        </div>
      </div>

      @if (settings.foot || buttons.length) {
        <div class="row table-footer-container">
          <div class="col-md table-foot">
            <div class="buttonContainer">
              <app-buttons [buttons]="buttons" (event)="onEvent($event)" (deleteSelectedRows)="deleteRows()" [rowsSelected]="selectedRows.length" />
            </div>

            <div class="extra">Er worden {{ totalShownRows }} rij(en) van {{ totalRows }} weergegeven.</div>
          </div>
        </div>
      }
    </div>
  </div>
</div>

<ng-template #loop let-data="data">
  <div class="inner-table">
    <table [class.fixed]="isFixedLayout" [class.first]="data.first || false">
      @for (row of data.rows; track row) {
        @if (headers.size && $index === 0 && options.head) {
          <tr class="header">
            @for (header of headers | keyvalue; track header) {
              @if (header.value.visible) {
                <th [style.width]="header.value.options.width" (click)="onSort(header.value)" [ngClass]="header.value.sort && 'sortable'">
                  <div class="table-head-inner">
                    @if (header.value.options.icon && header.value.visible) {
                      <span [innerHTML]="createIconElement(header.value.options.icon) | iconReplace"></span>
                    }
                    <span [title]="header.value.label" [innerText]="header.value.label"></span>
                    @if (header.value.sort) {
                      <span class="sort-icon">
                        <mat-icon>
                          @switch (header.value.sort.direction) {
                            @case ("DESC") {
                              expand_more
                            }
                            @case ("ASC") {
                              expand_less
                            }
                            @case ("INITIAL") {
                              unfold_more
                            }
                          }
                        </mat-icon>
                      </span>
                    }
                  </div>
                </th>
              }
            }
            @if (settings.selectable) {
              <th>
                <mat-checkbox
                  [checked]="data.group.getSelected().length === data.group.rows.size"
                  [indeterminate]="data.group.someSelected()"
                  (change)="data.group.selectAll($event.checked); setSelectedRows()"
                  [aria-label]="'TABLE.TH.CHECKALL' | translate"
                />
              </th>
            }
          </tr>
        }
        @if (!row.deleted && row.fields.size) {
          <tr [ngClass]="{ highlight: row.highlighted }">
            @for (field of row.fields | keyvalue; track field) {
              @if ($any(field).key.visible) {
                <td [ngClass]="{ hidden: $any(field).value.hidden.value }">
                  <div class="field" (click)="log(field)">
                    <app-table-prefix [field]="$any(field).value" [tooltip]="$any(field).value.tooltip" (prefixevent)="onPrefixEvent($event, row, field.value)" />
                  </div>
                </td>
              }
            }
            @if (settings.selectable) {
              <td>
                <mat-checkbox [aria-label]="'TABLE.TD.CHECKSINGLE' | translate" (change)="row.select($event.checked); setSelectedRows()" [checked]="row.selected" />
              </td>
            }
          </tr>
        }
      }
      @for (group of data.groups | mapvalues; track group) {
        <mat-expansion-panel
          class="group"
          hideToggle
          expanded
          [ngClass]="{ hidden: group.hidden || group.nosearchresults }"
          (afterCollapse)="onCollapse(group)"
          (afterExpand)="onExpand(group)"
        >
          <mat-expansion-panel-header class="group-header">
            <span class="icon">
              <mat-icon>expand_more</mat-icon>
            </span>
            <span #groupHeader>
              @if (group.fieldname) {
                <b><span [innerHTML]="group.fieldname"></span>: </b>
              }
              <span [innerHTML]="group.label"></span> ({{ group.nestedTotals.shown }}/{{ group.nestedTotals.rows }})</span
            >
          </mat-expansion-panel-header>

          <table>
            <ng-container *ngTemplateOutlet="loop; context: { data: { rows: group.shown, groups: group.groups, group: group } }" />
          </table>
        </mat-expansion-panel>
      }
    </table>
  </div>
</ng-template>

<ng-template #switchfiltergrouped let-data="data">
  @for (level of filters; track level) {
    <span class="filtergroup">
      <mat-checkbox labelPosition="before" (change)="toggleFilterGroup(level.level, $event.checked, level.filters)" [checked]="filterGroupActive(level.filters)">
        <span [innerHTML]="level.filters.length ? level.filters[0].leveldesc : ''"></span>
      </mat-checkbox>
    </span>

    @for (option of level.filters; track option) {
      <span class="filter">
        <mat-checkbox (change)="toggleFilterIndividual(level.level, option, $event.checked)" [checked]="filterActive(option)" />
        <span [innerHTML]="option.value"></span>
      </span>
    }
  }
</ng-template>
