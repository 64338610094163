import { ComponentType } from "@angular/cdk/portal";
import { Injectable, inject } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { take } from "rxjs";
import { ENVIRONMENT } from "src/environments/environment";
import { DialogComponent } from "./impl/dialog.component";
import { DialogTemplateComponent } from "./template/dialog-template.component";
import { ForceComponent } from "./template/force/force.component";
import { MainComponent } from "./template/main/main.component";
import { ModalComponent } from "./template/modal/modal.component";

export enum DialogTemplate {
  DEFAULT = 0,
  MODAL = 1,
  FORCE = 2,
}

export interface InstanceData<DATA = unknown> {
  instance: DialogComponent;
  config: MatDialogConfig;
  type: ComponentType<DialogComponent>;
  ref: MatDialogRef<DialogTemplateComponent>;
  data: DATA;
}

@Injectable({
  providedIn: "root",
})
export class DialogService {
  private dialog: MatDialog;

  public templates: Map<number, ComponentType<DialogTemplateComponent>>;
  public instances: Map<string, InstanceData>;

  public constructor() {
    this.dialog = inject(MatDialog);

    this.templates = new Map();
    this.instances = new Map();

    this.templates.set(0, MainComponent);
    this.templates.set(1, ModalComponent);
    this.templates.set(2, ForceComponent);
  }

  /**
   * Open dialog
   * @param type
   * @param data
   * @param template
   * @param config
   * @returns
   */
  public open<DATA = unknown>(
    type: ComponentType<DialogComponent>,
    data: DATA | null = null,
    template: DialogTemplate = DialogTemplate.DEFAULT,
    config: Partial<MatDialogConfig> = {},
  ): MatDialogRef<DialogTemplateComponent> {
    const templateComponent = this.templates.get(template);

    if (templateComponent) {
      const ref = this.dialog.open<DialogTemplateComponent, DATA>(templateComponent, {
        disableClose: false,
        closeOnNavigation: false,
        data: data,
        ...config,
      });

      const instance = ref.componentInstance;
      instance.config = config;
      instance.type = type;

      instance.oninstance.pipe(take(1)).subscribe((instance) => {
        this.instances.set(instance.uuid, {
          instance,
          config,
          type,
          data,
          ref,
        });
      });

      return ref;
    } else {
      throw new Error("Undefined dialog template.");
    }
  }

  /**
   * Remove instance from map
   * @param uuid
   */
  public close(uuid: string): void {
    const instance = this.instances.get(uuid);

    if (instance) {
      instance.ref.close();
      this.instances.delete(uuid);
    } else {
      if (ENVIRONMENT.DEBUG) console.warn("Could not find dialog instance => ", uuid);
    }
  }
}
